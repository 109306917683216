$(document).ready(function(){


    /**
     * Free Text Toggle
     */
    function bindFreeTextToggle() {
      $('.free-text-toggle').each(function() {
        $(this).on('click', function(e) {
          e.preventDefault();
          if ($(this).data('toggled') === true) {
            $(this).parent().next('.free-text').slideUp();
            $(this).text('+');
            $(this).data('toggled', false);
          } else {
            $(this).parent().next('.free-text').slideDown();
            $(this).text('−');
            $(this).data('toggled', true);
          }
        });
      });
    }

    bindFreeTextToggle();

    // Used to detect initial (useless) popstate.
    // If history.state exists, pushState() has created the current entry so we can
    // assume browser isn't going to fire initial popstate
    // var popped = ('state' in window.history && window.history.state !== null),
        // initialURL = location.href;

    var content = $('#js-main');

    if (history && history.pushState) {
      // history.pushState({}, null, null);
    }


    // Kolla http://stackoverflow.com/questions/26564212/bootstrap-modal-and-html5-history-api

    // testa detta http://stackoverflow.com/questions/10668427/using-history-js-with-dynamic-ajax-modal-boxes

    // Handle click event of all links with href not starting with http, https or #
    $('.js-ajax-link').on('click', function(e){
        e.preventDefault();

        //http://stackoverflow.com/a/19445707
        if ($('.pin-indicator').hasClass('active')) {
          $('.preloader').addClass('preloader--pinned').show();
        } else {
          $('.preloader').removeClass('preloader--pinned').show();
        }

        openOverlay();

        var href = $(this).attr('href');

        $.ajax({
          url: href,
          type:'GET',
          success: function(data) {
            var $response = $(data);

            var postdata = $response.find('#js-main').html();

            $('#js-main').html( postdata );

            fluidvids.init({
              selector: ['iframe', 'object'],
            });

            bindFreeTextToggle();

            $('.pin-indicator').addClass('hidden').removeClass('flash');

            $('.preloader').hide();
          }
        });

        console.log('pushstate fired');
        window.history.pushState({openModal: true}, '', $(this).attr('href'));
    });

    window.onpopstate =  function(event) {
      var state = history.state || {};

      state.openModal ? openOverlay() : closeOverlay();

      console.log('popstate fired');
    };
});

// Cache active menu item for re-highlighting later on.
var activeMenuItem = $('.menu-item.active');


var openOverlay = function() {
  $('body').addClass('overlay-open');

  // $('body').addClass('navigation-open');

  $('.menu-item').removeClass('active');


  $('.overlay.overlay--post').addClass('js-open');
};

var closeOverlay = function() {
  $('body').removeClass('overlay-open');

  // $('body').removeClass('navigation-open');

  $('.overlay').removeClass('js-open');

  $('.pin-indicator').stop().removeClass('hidden');

  // $('.search-field').val('');

  $('#js-main').empty();


  // Re-add the highlight class to menu item.
  activeMenuItem.addClass('active');


  console.log('close overlay');
};

// (function() {
//   $('.js-search-toggle a').on('click', function(e) {
//     e.preventDefault();
//     // openSearchOverlay();
//   });
// })();


$('.entry-link').on('click', function(e) {
  e.preventDefault();
  if(!$('.overlay.overlay--post').hasClass('js-open')) {
    openOverlay();
  } else {
    closeOverlay();
  }
});

$(document).on('keyup', function(e) {
  if(e.keyCode === 27) {
    if($('body').hasClass('overlay-open')) {
      if ($('.overlay--post').hasClass('js-open')){
        closeOverlay();
        history.back();
      } else {
        closeOverlay();
      }
    }
  }
});

$('.js-close-overlay').on('click', function(e) {
  e.preventDefault();

  if ($('.overlay--post').hasClass('js-open')){
    closeOverlay();
    history.back();
  } else {
    closeOverlay();
  }
});
