'use strict';

// Bra upplägg för JS-moduler som jag kanske kan använda: https://github.com/WebDevStudios/wd_s/tree/master/assets/scripts/concat

/*! fluidvids.js v2.4.1 | (c) 2014 @toddmotto | https://github.com/toddmotto/fluidvids */
!function(e,t){"function"==typeof define&&define.amd?define(t):"object"==typeof exports?module.exports=t:e.fluidvids=t()}(this,function(){"use strict";function e(e){return new RegExp("^(https?:)?//(?:"+d.players.join("|")+").*$","i").test(e)}function t(e,t){return parseInt(e,10)/parseInt(t,10)*100+"%"}function i(i){if((e(i.src)||e(i.data))&&!i.getAttribute("data-fluidvids")){var n=document.createElement("div");i.parentNode.insertBefore(n,i),i.className+=(i.className?" ":"")+"fluidvids-item",i.setAttribute("data-fluidvids","loaded"),n.className+="fluidvids",n.style.paddingTop=t(i.height,i.width),n.appendChild(i)}}function n(){var e=document.createElement("div");e.innerHTML="<p>x</p><style>"+o+"</style>",r.appendChild(e.childNodes[1])}var d={selector:["iframe","object"],players:["www.youtube.com","player.vimeo.com"]},o=[".fluidvids {","width: 100%; max-width: 100%; position: relative;","}",".fluidvids-item {","position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;","}"].join(""),r=document.head||document.getElementsByTagName("head")[0];return d.render=function(){for(var e=document.querySelectorAll(d.selector.join()),t=e.length;t--;)i(e[t])},d.init=function(e){for(var t in e)d[t]=e[t];d.render(),n()},d});

/**
 * Fluidvids.js
 */
fluidvids.init({
  selector: ['iframe', 'object'], // runs querySelectorAll()
  // players: ['www.youtube.com', 'player.vimeo.com'] // players to support
});


/**
 * Scroll to single contributor
 */
// $(document).ready(function () {
//   if ($('body').hasClass('single-contributor')) {
//     $('body').animate({
//         scrollTop: $('.entry-content .active').offset().top - ($('.main-navigation').outerHeight() * 2)
//     }, 'slow');
//   }
// });


// $(window).load(function() {
//   if ($('body').hasClass('single-contributor')) {
//     $('html, body').animate({
//         scrollTop: $('.entry-content .active').offset().top - ($('.main-navigation').outerHeight() * 2)
//     }, 'slow');
//   }
// });


(function($){
  $(window).on('load', function() {
    if ($('body').hasClass('single-contributor')) {
      $('html, body').animate({
          scrollTop: $('.entry-content .active').offset().top - ($('.main-navigation').outerHeight() * 2)
      }, 'slow');
      console.log('yep');
    }
  });


})(jQuery);


/**
 * Update positions/height
 */
(function () {
  var winHeight = $(window).innerHeight();

  $(document).ready(function () {
    $('.panel').height(winHeight);
    // $('body').height( winHeight * $('.panelCon-1 .panel').length);

    $('#left').scrollTop(0);
    $('#right').scrollTop($('#right').find('.panels').height());


    /**
     * Make-shift
     */
    var articleHeight = $('.panel-1', '#left').find('article').height();

    $('.panel.spacer').css({height: 'calc( 100vh - ' + ( $(window).innerHeight() - articleHeight ) / 2 + 'px' + ' - 1.953125rem * 1.5'});
  });

  window.addEventListener('resize', function () {
    var articleHeight = $('.panel-1', '#left').find('article').height();

    $('.panel').height($(window).innerHeight());
    $('.panel.spacer').css({height: 'calc( 100vh - ' + ( $(window).innerHeight() - articleHeight ) / 2 + 'px' + ' - 1.953125rem * 1.5'});
  });
})();


/**
 * Invert mousewheel in #right
 */
$('#right').on('mousewheel DOMMouseScroll', function(event) {
  var $elem = $(this);

      event.preventDefault();


  // var top = $elem.scrollTop();
  // var offset = $elem.find('.test').height() - $elem.height();

  var scroll = ( event.deltaY * event.deltaFactor ) * -1;

  var lastScroll = $elem.scrollTop();


  // console.log('offset: ' + offset);
  // console.log('lastScroll: ' + lastScroll);
  // console.log('scroll: ' + scroll);


  $elem.scrollTop( lastScroll - scroll );
      // return false;

});
