
/**
 * Synchronize scroll positions
 * @link http://jsfiddle.net/QDUyR/1/
 */
(function() {
  var $elements = $('#left, #right');
  var ticking = false;
  var master = "left"; // this is id div
  var slave = "right"; // this is other id div
  var master_tmp;
  var slave_tmp;
  var timer;

  var sync = function () {
    if ($(this).attr('id') == slave) {
      master_tmp = master;
      slave_tmp = slave;
      master = slave;
      slave = master_tmp;
    }

    $("#" + slave).unbind("scroll.fr.synchronizeScroll");

    var percentage = this.scrollTop * -1;

    var x = percentage + ($("#" + slave).get(0).scrollHeight - $("#" + slave).get(0).offsetHeight);

    $("#" + slave).scrollTop(x);

    if(typeof(timer) !== 'undefined') {
      clearTimeout(timer);
    }

    timer = setTimeout(function(){ $("#" + slave).on('scroll.fr.synchronizeScroll', sync); }, 100);
  };

  $('#' + master + ', #' + slave).on('scroll.fr.synchronizeScroll', sync);
})();
