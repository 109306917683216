/**
 * Preloader
 */
$(window).load(function() {
  $('.preloader').fadeOut('slow', function() {
    $(this).hide();
    if ($('body').hasClass('home')) {
      $('<div class="pin-indicator">').appendTo('.wrap');
    }
  });
});
