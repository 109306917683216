$(window).load(function(){
  if (!$('body').hasClass('home')) {
    return;
  }

  // Bind to #left since it has a natural scrolling position
  var $items = $('div[class*=panel-]', '#left');

  var contributors = [];

  // Create array of data-slug attributes from #right
  $('div[class*=panel-] .contributor', '#right').map(function(){
    contributors.push([$(this).attr('data-slug'), $(this).attr('data-permalink')]);
  });

  // console.log(contributors);

  contributors.reverse(); // Reverse to accomodate the reversed scrolling in #right

  $.each($items, function( index, value ) {

    var contributorDownWaypoint = new Waypoint({
      element: $(this)[0],
      handler: function(direction) {
        if (direction === 'down') {
            $('.contributor-link span').text('contributors/' + contributors[index][0].replace(/\s+/g, '-').toLowerCase());
            // $('.contributor-link').attr('href', '/dev/foundreview.com/contributors/' + (  contributors[index].replace(/\s+/g, '-').toLowerCase()  ) );

            $('.contributor-link').attr('href', contributors[index][1].replace(/\s+/g, '-').toLowerCase());

          // $('.contributor-link span').text('Posted by ' + ( contributors[index] ) );
          console.log('Contributor ' + ( index ));
          console.log('Handler 1 triggered in ' + direction + ' direction');
        }
      },
      context: $('#left')[0],
      offset: '50%'
    });

    var contributorUpWaypoint = new Waypoint({
      element: $(this)[0],
      handler: function(direction) {
        if (direction === 'up') {

          if ( typeof contributors[index - 1] !== 'undefined' ) {

            $('.contributor-link span').text('contributors/' + contributors[index - 1][0].replace(/\s+/g, '-').toLowerCase());
            $('.contributor-link').attr('href', contributors[index - 1][1].replace(/\s+/g, '-').toLowerCase());
            console.log('Contributor ' + ( index - 1));
          }
          console.log('Handler 2 triggered in ' + direction + ' direction');
        }
      },
      context: $('#left')[0],
      offset: function() {
        return $(this.element).height() / 2;
      }
    });

  });
});
