/**
 * @link http://stackoverflow.com/a/32283147
 */
(function(){
  $('#left, #right').on('scroll.fr.toggleFooter', function(event) {
    if (($('#left').find('.panels')[0].scrollHeight - $('#left').scrollTop() - $('#left').outerHeight()) < 5) {
      $('body').addClass('footer-toggled');
    } else {
      $('body').removeClass('footer-toggled');
    }
  });
})();
