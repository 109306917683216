/**
 * Only show fade on elements that have overflow.
 * @link http://stackoverflow.com/a/7668692
 */
$(window).load(function() {
  var elements = $('.entry-featured-image');
  $.each(elements, function(index, value) {
    var element = value;
    if( (element.offsetHeight < element.scrollHeight) || (element.offsetWidth < element.scrollWidth)){
      // Element has overflow
      $(element).addClass('overflowing');
    }
  });
});
