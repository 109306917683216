// Kanske kan ändras till nåt mer pålitligt?
if ($('body').hasClass('home')) {


function getPanelNth(el) {
  var classes = $(el).closest('.panel').prop('className'),
      singleClass = (classes.match(/(^|\s)panel-\S+/g) || []).join(' '),
      singleClassString = (singleClass.match(/\d+/g) || []).join(' ');

  return singleClassString;
}

$(function() {
  $('article img', '#left, #right').hover(function(){
    console.log('article hover');
    var classes = $(this).closest('.panel').prop('className');
    var singleClass = (classes.match(/(^|\s)panel-\S+/g) || []).join(' ');
    var singleClassString = (singleClass.match(/\d+/g) || []).join(' ');

    $('.panel-' + singleClassString).addClass('js-highlight-sibling');
  },
  function(){
    var classes = $(this).closest('.panel').prop('className');
    var singleClass = (classes.match(/(^|\s)panel-\S+/g) || []).join(' ');
    var singleClassString = (singleClass.match(/\d+/g) || []).join(' ');

    $('.panel-' + singleClassString).removeClass('js-highlight-sibling');
  });
});

/*
  Needs cleanup
 */

$(function(){
  $(".entry-title .meta-field").on("mouseenter", function() {
    var $this = $(this);
    $('.panel-' + getPanelNth(this)).find(".entry-title .meta-field:nth-child(" + ($this.index() + 1) + ")").addClass('highlight');
  });
  $(".entry-title .meta-field").on("mouseleave", function() {
    var $this = $(this);
    $('.panel-' + getPanelNth(this)).find(".entry-title .meta-field:nth-child(" + ($this.index() + 1) + ")").removeClass('highlight');
  });
});


$(function(){
  $(".entry-meta .meta-field").on("mouseenter", function() {
    var $this = $(this);
    $('.panel-' + getPanelNth(this)).find(".entry-meta .meta-field:nth-child(" + ($this.index() + 1) + ")").addClass('highlight');
  });
  $(".entry-meta .meta-field").on("mouseleave", function() {
    var $this = $(this);
    $('.panel-' + getPanelNth(this)).find(".entry-meta .meta-field:nth-child(" + ($this.index() + 1) + ")").removeClass('highlight');
  });
});

}
