//https://codepen.io/patrickkunka/pen/iwcap
//To keep our code clean and modular, all custom functionality will be contained inside a single object literal called "checkboxFilter".

var checkboxFilter = {

  // Declare any variables we will need as properties of the object

  $filters: null,
  $reset: null,
  groups: [],
  outputArray: [],
  outputString: '',

  // The "init" method will run on document ready and cache any jQuery objects we will need.

  init: function(){
    var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "checkboxFilter" object so that we can share methods and properties between all parts of the object.

    self.$filters = $('.filters');
    self.$reset = $('#Reset');
    self.$container = $('.portfolio-list');

    self.$filters.find('.button-group').each(function(){
      self.groups.push({
        $inputs: $(this).find('a[data-filter]'),
        active: [],
        tracker: false
      });
    });

    self.bindHandlers();

  },

  // The "bindHandlers" method will listen for whenever a form value changes.

  bindHandlers: function(){
    var self = this;

    self.$filters.on('click', 'a[data-filter]', function(e){

      e.preventDefault();

      if (!$(this).hasClass('active')) {
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }

      self.parseFilters();
      console.log('filter click');
    });

    self.$reset.on('click', function(e){
      e.preventDefault();
      self.resetFilters();
      // self.$filters.find('.button').removeClass('active');
      self.parseFilters();

      console.log('reset click');

    });
  },

  // The parseFilters method checks which filters are active in each group:

  resetFilters: function() {
    var self = this;
    self.$filters.find('a[data-filter]').removeClass('active');
  },

// The parseFilters method checks which filters are active in each group:

  parseFilters: function(){
    var self = this;

    // loop through each filter group and add active filters to arrays

    for(var i = 0, group; group = self.groups[i]; i++){
      group.active = []; // reset arrays
      group.$inputs.each(function(){
        $(this).is('.active') && group.active.push($(this).attr('data-filter'));
      });
      group.active.length && (group.tracker = 0);
    }

    // if (group.active.length) {
    //   self.$filters.addClass('filters-active');
    // } else {
    //   self.$filters.removeClass('filters-active');
    // }

    self.concatenate();
  },

  // The "concatenate" method will crawl through each group, concatenating filters as desired:

  concatenate: function(){
    var self = this,
      cache = '',
      crawled = false,
      checkTrackers = function(){
        var done = 0;

        for(var i = 0, group; group = self.groups[i]; i++){
          (group.tracker === false) && done++;
        }

        return (done < self.groups.length);
      },
      crawl = function(){
        for(var i = 0, group; group = self.groups[i]; i++){
          group.active[group.tracker] && (cache += group.active[group.tracker]);

          if(i === self.groups.length - 1){
            self.outputArray.push(cache);
            cache = '';
            updateTrackers();
          }
        }
      },
      updateTrackers = function(){
        for(var i = self.groups.length - 1; i > -1; i--){
          var group = self.groups[i];

          if(group.active[group.tracker + 1]){
            group.tracker++;
            break;
          } else if(i > 0){
            group.tracker && (group.tracker = 0);
          } else {
            crawled = true;
          }
        }
      };

    self.outputArray = []; // reset output array

    do{
      crawl();
    }
    while(!crawled && checkTrackers());

    self.outputString = self.outputArray.join();

    // If the output string is empty, show all rather than none:

    if (!self.outputString.length) {
      // self.outputString = '*';
      // self.outputString = '';
      self.$container.removeClass('filters-active');
    } else {
      self.$container.addClass('filters-active');
    }


    // ^ we can check the console here to take a look at the filter string that is produced

    // Send the output string to MixItUp via the 'filter' method:



      console.log(self.outputString);



      function containsAll(needles, haystack){
        for(var i = 0 , len = needles.length; i < len; i++){
           if($.inArray(needles[i], haystack) == -1) return false;
        }
        return true;
      }

      // if (self.outputArray.length > 0 && self.outputArray[0] !== '') {


      // self.$container.mixItUp('filter', self.outputString);

      self.$container.find('li').removeClass('show').filter(self.outputString).addClass('show');

      // self.$container.find('li').removeClass('show').filter(function(index) {
      //   return containsAll(self.outputArray, $(this).attr('data-filter').split(/\s+/) );
      // }).addClass('show');

      // }

      // if (self.outputString != '.') {
      //   self.$container.find('li').removeClass('show').filter(self.outputString).addClass('show');
      // }


    // }
  }
};

// On document ready, initialise our code.

$(function(){

  // Initialize checkboxFilter code

  checkboxFilter.init();

    // $('.portfolio-list').addClass('filters-active');

    // $('.portfolio-list').find('li').removeClass('show').filter('.queer-politics').addClass('show');


  var url = window.location.href;
  var taxonomy = "";
  var term = "";
  var matches = url.match(/\/archive\/([^\/]+)\/([^\/]+)/);
  if (matches && matches.length > 1) {
    taxonomy = matches[1];    // "whatever"
    term = matches[2];    // "whatever"

    console.log(taxonomy + term);


    var $filterElement = $('ul[data-filter-group="' + matches[1] + '"] a[data-filter=".' + matches[2] + '"]');

    console.log($filterElement);

    if ($filterElement.length) {
      $('.portfolio-list').addClass('filters-active');
      $('.portfolio-list').find('li').removeClass('show').filter('.' + matches[2] + '').addClass('show');



      $filterElement.addClass('active');
    }



  } else {
      // no match for the category
  }


  // Instantiate MixItUp

  // $('#Container').mixItUp({
  //   controls: {
  //     enable: false // we won't be needing these
  //   },
  //   animation: {
  //     easing: 'cubic-bezier(0.86, 0, 0.07, 1)',
  //     duration: 600
  //   }
  // });
});
