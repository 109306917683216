/**
 * Match Height
 */
$(function() {
  var items = $('.panel');

  $.each(items, function(i, val) {
    // Find elements with corresponding class number
    var itemClasses = $(val).prop('className');
    var singleClass = (itemClasses.match(/(^|\s)panel-\S+/g) || []).join(' ');
    var singleClassString = (singleClass.match(/\d+/g) || []).join(' ');

    $('.panel-' + singleClassString).find('.js-mh').matchHeight({
      byRow: false,
      property: 'height',
      target: null,
      remove: false
    });
  });

  // if ($('body').hasClass('single')) {
  //   $('.js-mh').matchHeight();
  // }
});
