$('.menu-toggle__handler').on('mouseover', function(e) {
  $('body').addClass('navigation-open');
});

$('.menu').on('mouseleave', function(e) {
  if (!$('body').hasClass('overlay-open')) {

    $('body').removeClass('navigation-open');

  }
});
