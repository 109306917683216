/**
 * ScrollMagic
 */

$(function () {
  if (!$('body').hasClass('home')) {
    return;
  }

  // Init controller
  var controller1 = new ScrollMagic.Controller({container: "#left"});

  // Front
  $('.panel.spacer + div', '#left').each(function(){
    var currentStrong = this;

    // var tlm = new TimelineMax()
    //   .add([
    //     TweenMax.to(".masthead-title", 0.25, {ease: SteppedEase.config(1), opacity: 0}),

    //     // TweenMax.to(".masthead-title", 0.125, {ease: SteppedEase.config(1), opacity: 0}),
    //     // TweenMax.to(".banan", 0.125, {opacity: 1}),

    //     // TweenMax.to(".masthead", 1, {y: '-100%'})

    //     TweenMax.to(".front-page-2", 0.25, {ease: SteppedEase.config(1), opacity: 1}),

    //   ])
    //   .add([
    //     TweenMax.to(".front-page-2", 10, {ease: SteppedEase.config(1), opacity: 0 }, '0'),
    //   ]);

    var offsetHeight = $(window).innerHeight() - $(currentStrong).height();
    // function to be used to retrieve variable
    function getOffsetHeight() {
      console.log(offsetHeight);
      return offsetHeight;
    }

    window.addEventListener('resize', function () {
      offsetHeight = $(window).innerHeight() - $(currentStrong).height();
    });

    function myFunction() {
      console.log('callback');
    }

    var tlm = new TimelineMax()
      // .add([
        // .to(".masthead-title", 0.0625, {ease: SteppedEase.config(1), opacity: 0}, 0)

        // .add( function(){
        //   if ($('.masthead-title').is(':hidden')) {
        //     $('.masthead-title').show();
        //     // $('.front-page-2').css({opacity: 0});
        //   } else {
        //     $('.masthead-title').hide();
        //     // $('.front-page-2').css({opacity: 1});
        //   }
        // }, 0.125 )

        // TweenMax.to(".masthead-title", 0.125, {ease: SteppedEase.config(1), opacity: 0}),
        // TweenMax.to(".banan", 0.125, {opacity: 1}),

        // TweenMax.to(".masthead", 1, {y: '-100%'})

        // .to(".front-page-2", 0.0625, {ease: SteppedEase.config(1), opacity: 1}, 0)
        // .add( function(){
        //   if($('body').hasClass('navbars-toggled')) {
        //     $('body').removeClass('navbars-toggled');
        //   } else {
        //     $('body').addClass('navbars-toggled');
        //   }
        // }, 0.0625 )

      // ])
      // .add([
      //   TweenMax.to(".front-page-2", 98, {ease: SteppedEase.config(1), opacity: 1}),
      // ])
      // .add([
        .to(".masthead-title", 1, {ease: SteppedEase.config(1), opacity: 0 }, 1);
      // ]);

    // Build scene
    var scene3 = new ScrollMagic.Scene({triggerElement: currentStrong, triggerHook: 0.5, offset: '-150%', duration: function() {
      return $(window).height() - $('.panel.spacer', '#left').height();
    }})
    // var scene3 = new ScrollMagic.Scene({triggerElement: currentStrong, triggerHook: 0.5, offset: '-100%', duration: function() {return $(currentStrong).height();}})
      .setTween( tlm )
      // .addIndicators({name: "3 (offset: '-200%', duration: '25%')"}) // add indicators (requires plugin)
      .addTo(controller1);

  });

$('.panel-1', '#left').each(function(){
    var currentStrong = this;

  var scene4 = new ScrollMagic.Scene({triggerElement: this, triggerHook: 0, duration: '100%'})
          // .setClassToggle("body", "navbars-toggled") // add class toggle
          .on("enter leave", function (e) {
            // $("#state").text(e.type == "enter" ? "inside" : "outside");

            if (e.scrollDirection === "FORWARD") {
              $('body').addClass('navbars-toggled');
            }

            if (e.scrollDirection === "REVERSE") {
              $('body').removeClass('navbars-toggled');
            }

            // scene4.update(true);


            // if($('body').hasClass('navbars-toggled')) {
            //   $('body').removeClass('navbars-toggled');
            // } else {
            //   $('body').addClass('navbars-toggled');
            // }

          })
          // .addIndicators() // add indicators (requires plugin)
          .addTo(controller1);
    });

  function triggerFeedback(e) {

    // if (e.scrollDirection === "FORWARD") {
    //   $('body').addClass('navbars-toggled');
    // }

    // if (e.scrollDirection === "REVERSE") {
    //   $('body').removeClass('navbars-toggled');
    // }

    if ($('.pin-indicator').hasClass('active')) {
      $('.pin-indicator').removeClass('active');
    } else {
      $('.pin-indicator').addClass('active');
    }

    // if ($('body').hasClass('locked')) {
    //   $('body').removeClass('locked');
    // } else {
    //   $('body').addClass('locked');
    // }
  }


  function triggerFeedback2(e) {
    // console.log('event: ' + e);

    console.log($(e.target.triggerElement()).find('.contributor').attr('data-slug'));

    // console.log('right-trigger');
  }

  // #left pinned panels
  $('div[class*=panel-]', '#left').each(function(){
    var currentStrong = this;

    // Build scene
    var scene1 = new ScrollMagic.Scene({triggerElement: currentStrong, triggerHook: 0, duration: "125%"})
      .addTo(controller1)
      // .addIndicators({name: "1 (duration: 125%)"}) // add indicators (requires plugin)
      .setPin(currentStrong)
      .on("enter leave", triggerFeedback);
  });

  // Init controller
  var controller2 = new ScrollMagic.Controller({container: "#right"});

  // #right pinned panels
  $('div[class*=panel-]', '#right').each(function(){
    var currentStrong = this;

    // Build scene
    var scene2 = new ScrollMagic.Scene({triggerElement: currentStrong, triggerHook: 0, duration: "125%"})
      .addTo(controller2)
      // .addIndicators({name: "2 (duration: 125%)"}) // add indicators (requires plugin)
      .setPin(currentStrong)
      // .on("enter", triggerFeedback2);
  });
});
